import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function FooterHomeOne({ className }) {
  return (
    <>
      <section className={`appie-footer-area ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <p>
                  The best Auto Solution for you and your showroom maintain
                  everything in one place.
                </p>
                {/* <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Quick Links</h4>
                <ul>
                  <li>
                    <a href="#about_us">About Us</a>
                  </li>
                  <li>
                    <a href="#product">Product</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="#testimonials">Testimonials</a>
                  </li>
                  <li>
                    <a href="#faq">FAQ</a>
                  </li>
                  <li>
                    <a href="#contact_us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="mailto:support@ehautosolutions.com">
                      <i className="fal fa-envelope" />{" "}
                      support@ehautosolutions.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+918108291244">
                      <i className="fal fa-phone fa-rotate-90" /> +91 8108291244
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" /> E/6, Adenwala
                      Compound, Mukund J Marg, Parel, Mumbai - 400012
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                {/* <div className="apps-download-btn">
                  <ul>
                    <li>
                      <a href="tel:+918180291244">
                        <i className="fa fa-phone fa-rotate-90" /> Call Now
                      </a>
                    </li>
                    <li>
                      <a
                        className="item-2"
                        href="https://api.whatsapp.com/send?phone=8180291244"
                        target="_blank"
                      >
                        <i className="fab fa-whatsapp" /> Whatsapp Now
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="copyright-text">
                  <p>
                    Copyright © 2022 EH Auto Solutions. All rights reserved.
                    Developed By{" "}
                    <a href="https://sysmarche.com" target="_blank">
                      Sysmarche Infotech
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
