import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import user from "../../assets/images/testimonial-user.png";

function TestimonialHomeOne() {
  const sliderRef = useRef();
  const sliderNext = () => {
    sliderRef.current.slickNext();
  };
  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <section className="appie-testimonial-area pt-30 pb-30" id="testimonials">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="appie-section-title text-center">
              <h3 className="appie-title">Testimonial</h3>
            </div>
          </div>
          <div className="col-lg-8">
            <div
              className="appie-testimonial-slider"
              style={{ position: "relative" }}
            >
              <span
                className="prev slick-arrow"
                style={{ display: "block" }}
                onClick={sliderNext}
                role="button"
                tabIndex="0"
              >
                <i className="fal fa-arrow-left" />
              </span>
              <Slider ref={sliderRef} dots arrows={false}>
                <div className="appie-testimonial-item text-center">
                  <div className="author-info">
                    <img src={user} alt="" />
                    <h5 className="title">Swapnil Mhaske</h5>
                    <span>April 14, 2022</span>
                  </div>
                  <div className="text">
                    <p>
                      EH Auto Solutions is transforming the Two Wheeler Sales
                      and Service world by constantly providing breakthrough
                      software solutions for Sales and Service Industry.
                    </p>
                    <ul>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="appie-testimonial-item text-center">
                  <div className="author-info">
                    <img src={user} alt="" />
                    <h5 className="title">Devendra Ahirwar</h5>
                    <span>April 14, 2022</span>
                  </div>
                  <div className="text">
                    <p>
                      If you are looking to best sales and services software for
                      your Two wheeler showroom business, EH Auto Solutions is
                      the place you should go to.
                    </p>
                    <ul>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="appie-testimonial-item text-center">
                  <div className="author-info">
                    <img src={user} alt="" />
                    <h5 className="title">Jitendra Patwa</h5>
                    <span>April 14, 2022</span>
                  </div>
                  <div className="text">
                    <p>
                      This is a good solution for vehicle sale and service
                      industries.
                    </p>
                    <ul>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                      <li>
                        <i className="fas fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Slider>
              <span
                onClick={sliderPrev}
                role="button"
                tabIndex="-1"
                className="next slick-arrow"
                style={{ display: "block" }}
              >
                <i className="fal fa-arrow-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialHomeOne;
