import React from "react";
import thumb from "../../assets/images/admin_dashboard.png";
import vision_img from "../../assets/images/our_vision.png";

function VisionMission() {
  return (
    <section className="appie-traffic-area pt-30 pb-30" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="appie-features-thumb text-center wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <img src={vision_img} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="appie-section-title text-justify">
              <h3 className="appie-title">Our Vision</h3>
              <p>
                We want our software to used by every two wheeler and four
                wheeler companies pan India as it is reliable,transparent & cost
                effective.
              </p>
              <p>
                Our vision is to make more such softwares that can be helpful
                for the dealers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VisionMission;
