import React from "react";
import heroThumbOne from "../../assets/images/home-product.jpg";
import heroThumbTwo from "../../assets/images/hero-thumb-2.png";
import shapeTwo from "../../assets/images/shape/shape-2.png";
import shapeThree from "../../assets/images/shape/shape-3.png";
import shapeFour from "../../assets/images/shape/shape-4.png";

function HeroHomeOne() {
  return (
    <>
      <section className="appie-hero-area" id="homeone">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <div className="appie-section-title text-center">
                  <h3 className="appie-title">
                    Welcome To <br /> EH Auto Solutions.
                  </h3>
                  <p>
                    <h4 className="cus-hero-title">
                      <b>❝ Do you own a Two or Four wheeler dealership? ❞</b>
                    </h4>
                    <br /> <span className="cus-para-text">❝ Tired of maintaining records, tracking every customer
                    details and progress status ? ❞</span>
                    <br />
                    <br /> Here is India’s first automated Sales Dealer
                    Management Software, where in you get everything at one
                    place. With this software you will not be dependent on
                    anyone to have a detailed information on customer’s booking
                    progress status.
                  </p>
                </div>
                <ul>
                  <li>
                    <a href="tel:+918108291244">
                      <i className="fa fa-phone fa-rotate-90" /> Call Now
                    </a>
                  </li>
                  <li>
                    <a
                      className="item-2"
                      href="https://api.whatsapp.com/send?phone=8180291244"
                      target="_blank"
                    >
                      <i className="fab fa-whatsapp" /> Whatsapp Now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-hero-thumb">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={heroThumbOne} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="hero-shape-1">
          <img src={shapeTwo} alt="" />
        </div>
        <div className="hero-shape-2">
          <img src={shapeThree} alt="" />
        </div>
        <div className="hero-shape-3">
          <img src={shapeFour} alt="" />
        </div> */}
      </section>
    </>
  );
}

export default HeroHomeOne;
