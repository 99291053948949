import React from "react";
import IconFive from "../../assets/images/icon/5.png";
import IconSix from "../../assets/images/icon/6.png";
import IconSeven from "../../assets/images/icon/7.png";
import IconEight from "../../assets/images/icon/8.png";
import IconNine from "../../assets/images/icon/9.png";
import IconTen from "../../assets/images/icon/10.png";
import IconEleven from "../../assets/images/icon/11.png";
import IconTwelve from "../../assets/images/icon/12.png";
import IconThirteen from "../../assets/images/icon/13.png";

function WhyChooseUs({ className }) {
  return (
    <section
      className={`appie-service-area why-choose-us-area pt-30 pb-30 ${className}`}
      id="about_us"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="appie-section-title text-justify">
              <h3 className="appie-title text-center">Why Choose Us</h3>
              <p>
                Most of the dealers are still using the OLD STANDARD MANUAL
                METHOD of sales process softwares and spending lakhs on them.
              </p>
              <p>
                With our software the dealer can now make this system fully
                automated by tracking every booking done at the showroom without
                having another person keeping a track of all these things.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <img src={IconFive} alt="" />
                <span>1</span>
              </div>
              <h4 className="appie-title why-choose-us">Staff Management</h4>
              <p className="custom-text">
                Create different users as per departments and assign work
                accordingly.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <img src={IconSix} alt="" />
                <span>2</span>
              </div>
              <h4 className="appie-title why-choose-us">Booking</h4>
              <p className="custom-text">
                Tracking from Start till end process.(Highlight of our software)
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <img src={IconSeven} alt="" />
                <span>3</span>
              </div>
              <h4 className="appie-title why-choose-us">Stock Inventory</h4>
              <p className="custom-text">Manage stocks.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <img src={IconEight} alt="" />
                <span>4</span>
              </div>
              <h4 className="appie-title why-choose-us">Invoice</h4>
              <p className="custom-text">Automated Invoice creation.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <img src={IconNine} alt="" />
                <span>5</span>
              </div>
              <h4 className="appie-title why-choose-us">Delivery Challan</h4>
              <p className="custom-text">Automated challan creation.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <img src={IconTen} alt="" />
                <span>6</span>
              </div>
              <h4 className="appie-title why-choose-us">Service Book</h4>
              <p className="custom-text">
                Automated manual book creation as per models.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <img src={IconEleven} alt="" />
                <span>7</span>
              </div>
              <h4 className="appie-title why-choose-us">
                MBO/Wholesaler Management
              </h4>
              <p className="custom-text">
                Easy management of number of dealers.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <img src={IconTwelve} alt="" />
                <span>8</span>
              </div>
              <h4 className="appie-title why-choose-us">Dashboard & Reports</h4>
              <p className="custom-text">
                One stop for every detail required, and More than 15+ reports.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
