import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { project_service } from "../../services";
import Swal from "sweetalert2";

function ContactHomeOne({ className }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };

  const [fullname, setFullName] = useState("");
  const [email_addr, setEmailAddress] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [user_message, setUserMessage] = useState("");

  const handleFullName = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailAddress = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleMobileNo = (e) => {
    setMobileNo(e.target.value);
  };

  const handleUserMessage = (e) => {
    setUserMessage(e.target.value);
  };

  const SendEnquiry = async () => {
    try {
      await project_service("", {
        api: "send_enquiry",
        request_data: {
          fullname: fullname,
          email_addr: email_addr,
          mobile_no: mobile_no,
          user_message: user_message,
        },
      }).then((response) => {
        if (response.code >= 200 && response.code <= 299) {
          Swal.fire("", response.msg, "success");
        } else {
          Swal.fire("", response.msg, "error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <section className="contact-section" id="contact_us">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Contact Us</h3>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact--info-area">
                <h3>Get in touch</h3>
                <p>
                  Looking for help? Fill the form and start a new adventure.
                </p>
                <div className="single-info">
                  <h5>Headquaters</h5>
                  <p>
                    <i className="fal fa-home"></i>
                    E/6, Adenwala Compound, Mukund J Marg, Parel, Mumbai -
                    400012
                  </p>
                </div>
                <div className="single-info">
                  <h5>Phone</h5>
                  <p>
                    <i className="fal fa-phone fa-rotate-90"></i>
                    <a href="tel:+918108291244">(+91) 8108291244</a>
                  </p>
                </div>
                <div className="single-info">
                  <h5>Support</h5>
                  <p>
                    <i className="fal fa-envelope"></i>
                    <a href="mailto:support@ehautosolutions.com">
                      support@ehautosolutions.com
                    </a>
                  </p>
                </div>
                {/* <div className="ab-social">
                  <h5>Follow Us</h5>
                  <a className="fac" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="twi" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="you" href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a className="lin" href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="contact-form">
                <h4>Let’s Connect</h4>
                {/* <p>Integer at lorem eget diam facilisis lacinia ac id massa.</p> */}
                <form action="#" method="post" className="row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Full Name"
                      value={fullname}
                      onChange={handleFullName}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="email_addr"
                      placeholder="Email Address"
                      value={email_addr}
                      onChange={handleEmailAddress}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="mobile_no"
                      className="cus-text-number-field"
                      placeholder="Mobile Number"
                      value={mobile_no}
                      onChange={handleMobileNo}
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="user_message"
                      placeholder="How can we help?"
                      value={user_message}
                      onChange={handleUserMessage}
                    ></textarea>
                  </div>
                  <div className="col-md-6">
                    <div className="condition-check">
                      {/* <input id="terms-conditions" type="checkbox" />
                      <label htmlFor="terms-conditions">
                        I agree to the <a href="#">Terms & Conditions</a>
                      </label> */}
                    </div>
                  </div>
                  <div className="col-md-6 text-right">
                    <input
                      type="button"
                      name="submit"
                      className="send-enquiry-btn"
                      onClick={SendEnquiry}
                      value="Send Message"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<div className="bisylms-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.5835316780735!2d72.82035271482057!3d18.993991587134968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf283f29e5e7%3A0x8b6531dcf3938efa!2sPRB%20SUZUKI%20-%20WORLI!5e0!3m2!1sen!2sin!4v1670049906497!5m2!1sen!2sin"
        ></iframe>
                    </div> */}
    </>
  );
}

export default ContactHomeOne;
