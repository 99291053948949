import React from "react";
import IconOne from "../../assets/images/icon/1.png";
import IconTwo from "../../assets/images/icon/2.png";
import IconThree from "../../assets/images/icon/3.png";
import IconFour from "../../assets/images/icon/4.png";
import AboutUsImg from "../../assets/images/about_us.png";

function ServicesHomeOne({ className }) {
  return (
    <section
      className={`appie-service-area pt-30 pb-30 ${className}`}
      id="about_us"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="appie-section-title text-justify">
              <h3 className="appie-title text-center">
                About EH Auto Solutions
              </h3>
              <p>
                We are a Mumbai based tech startup providing sales dealer
                management software solution across pan India.
              </p>
              <p>
                We have built an
                automated sales software for two wheelers and four wheelers
                where in we have only focused on the issues faced by the auto
                dealers.
              </p>
              <p>
                Through this software we have covered every possible loopholes
                that will make the process smooth and transparent for the owner,
                managers, sales executives, accounts, RTO & admin.
              </p>
            </div>
          </div>
          <div className="col-lg-12 about-us-image">
            <img src={AboutUsImg} alt="" className="about-us-img" />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
            >
              <div className="icon">
                <img src={IconOne} alt="" />
                <span>1</span>
              </div>
              <h4 className="appie-title">Vehicle Booking</h4>
              <p className="cus-text">
                Manage current and future bookings of vehicle
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="400ms"
            >
              <div className="icon">
                <img src={IconTwo} alt="" />
                <span>2</span>
              </div>
              <h4 className="appie-title">Stock Managment</h4>
              <p className="cus-text">Manage your vehicle models, companies</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <img src={IconThree} alt="" />
                <span>3</span>
              </div>
              <h4 className="appie-title">Automate Invoice</h4>
              <p className="cus-text">
                Generate Automatic invoice and send on clients email
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
              data-wow-duration="2000ms"
              data-wow-delay="800ms"
            >
              <div className="icon">
                <img src={IconFour} alt="" />
                <span>4</span>
              </div>
              <h4 className="appie-title">Reports</h4>
              <p className="cus-text">
                You can easily go through you day to day business by just seeing
                at your reports
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default ServicesHomeOne;
