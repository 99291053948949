import React, { useState } from "react";
import { Link } from "react-router-dom";
import thumb from "../../assets/images/our-products.png";
import shapeSix from "../../assets/images/shape/shape-6.png";
import shapeSeven from "../../assets/images/shape/shape-7.png";
import shapeEight from "../../assets/images/shape/shape-8.png";

function FeaturesHomeOne({ className }) {
  const [tab, setTab] = useState("setting");
  const handleClick = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section
      className={`appie-features-area pt-30 pb-30 ${className}`}
      id="product"
    >
      <div className="container">
        <div className="row align-items-center">
          {/* <div className="col-lg-3">
                        <div className="appie-features-tabs-btn">
                            <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <a
                                    onClick={(e) => handleClick(e, 'setting')}
                                    className={`nav-link ${tab === 'setting' ? 'active' : ''}`}
                                    id="v-pills-home-tab"
                                    data-toggle="pill"
                                    href="#v-pills-home"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    <i className="fas fa-cog" /> Settings
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'report')}
                                    className={`nav-link ${tab === 'report' ? 'active' : ''}`}
                                    id="v-pills-profile-tab"
                                    data-toggle="pill"
                                    href="#v-pills-profile"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-exclamation-triangle" /> Report
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'notice')}
                                    className={`nav-link ${tab === 'notice' ? 'active' : ''}`}
                                    id="v-pills-messages-tab"
                                    data-toggle="pill"
                                    href="#v-pills-messages"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-bell" /> Saeety Notices
                                </a>
                                <a
                                    onClick={(e) => handleClick(e, 'app')}
                                    className={`nav-link ${tab === 'app' ? 'active' : ''}`}
                                    id="v-pills-settings-tab"
                                    data-toggle="pill"
                                    href="#v-pills-settings"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    <i className="fas fa-lock" /> App Lock
                                </a>
                            </div>
                        </div>
                    </div> */}
          <div className="col-lg-12">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className={`${
                  tab === "setting" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content wow animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      {/* <span>Custom Reacyions</span> */}
                      <h3 className="title">Our Product</h3>
                      <p>
                        Best Vehicle Booking Software for Two Wheeler Showrooms
                      </p>
                      <p>
                        Finding reliable and all-on-one vehicle booking software
                        can be a real challenge for Two Wheeler dealers.
                      </p>
                      <p>
                        This web application will help you to Manage your
                        Vehicle's Booking, Insurance, Stock Management, Staff
                        Management, RTO Track and also Reports for Profit and
                        Loss under your budget.
                      </p>
                      {/* <Link className="main-btn" to="/about-us">
                                                Learn More
                                            </Link> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center wow animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "report" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Custom Reacyions</span>
                      <h3 className="title">
                        Let the <br /> Conversation flow
                      </h3>
                      <p>
                        Car boot absolutely bladdered posh burke the wireless
                        mush some dodg.
                      </p>
                      <a className="main-btn" href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "notice" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Custom Reacyions</span>
                      <h3 className="title">
                        Let the <br /> Conversation flow
                      </h3>
                      <p>
                        Car boot absolutely bladdered posh burke the wireless
                        mush some dodg.
                      </p>
                      <a className="main-btn" href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "app" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div
                      className="appie-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img src={thumb} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="appie-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span>Custom Reacyions</span>
                      <h3 className="title">
                        Let the <br /> Conversation flow
                      </h3>
                      <p>
                        Car boot absolutely bladdered posh burke the wireless
                        mush some dodg.
                      </p>
                      <a className="main-btn" href="#">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features-shape-1">
        <img src={shapeSix} alt="" />
      </div>
      <div className="features-shape-2">
        <img src={shapeSeven} alt="" />
      </div>
      <div className="features-shape-3">
        <img src={shapeEight} alt="" />
      </div>
    </section>
  );
}

export default FeaturesHomeOne;
