import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("home");
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${43 * getItems}px`);
      setItem(value);
    }
  };

  const open_home = (e) => {
    action(e);
    const element = document.getElementById("homeone");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_about_us = (e) => {
    action(e);
    const element = document.getElementById("about_us");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_product = (e) => {
    action(e);
    const element = document.getElementById("products");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_services = (e) => {
    action(e);
    const element = document.getElementById("services");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_testimonials = (e) => {
    action(e);
    const element = document.getElementById("testimonials");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_faq = (e) => {
    action(e);
    const element = document.getElementById("faq");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const open_contact_us = (e) => {
    action(e);
    const element = document.getElementById("contact_us");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  <a href="#" onClick={(e) => action(e)}>
                    <i className="fa fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-brand text-center mb-40">
                  <img src={logo} alt="" />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li id="home" className="menu-item-has-children active">
                      <a onClick={open_home}>Home</a>
                      {/* <ul
                                                className="sub-menu"
                                                style={{
                                                    height: item === 'home' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <Link to="/">Home 1</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-two">Home 2</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-three">Home 3</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-four">Home 4</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-five">Home 5</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-six">Home 6</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-seven">Home 7</Link>
                                                </li>
                                                <li>
                                                    <Link to="/home-eight">Home 8</Link>
                                                </li>
                                            </ul> */}
                    </li>
                    <li id="service" className="menu-item-has-children active">
                      <a onClick={open_about_us}>About Us</a>
                    </li>
                    {/* <li id="pages" className="menu-item-has-children active">
                      <span className="menu-expand">
                        <i className="fa fa-angle-down"></i>
                      </span>
                      <a onClick={open_product}>Product</a>
                    </li>
                    <li id="news" className="menu-item-has-children active">
                      <a onClick={open_services}>Services</a>
                    </li> */}
                    <li id="contact" className="menu-item-has-children active">
                      <a onClick={open_testimonials}>Testimonials</a>
                    </li>
                    <li id="contact" className="menu-item-has-children active">
                      <a onClick={open_faq}>FAQ</a>
                    </li>
                    <li id="contact" className="menu-item-has-children active">
                      <a onClick={open_contact_us}>Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-widget-info">
                  <ul>
                    <li>
                      <a href="mailto:support@ehautosolutions.com">
                        <i className="fal fa-envelope"></i>{" "}
                        support@ehautosolutions.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+918108291244">
                        <i className="fal fa-phone fa-rotate-90"></i> +91
                        8108291244
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-map-marker-alt"></i> E/6, Adenwala
                        Compound, Mukund J Marg, Parel, Mumbai - 400012
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
