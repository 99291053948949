import React, { useEffect } from 'react';
import TopToBottom from '../lib/TopToBottom';
import 'bootstrap/dist/css/bootstrap.min.css';

function BackToTop({ className }) {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <>
            <div className={`back-to-top ${className} d-md-none d-lg-none`}>
                <a href="tel:+918108291244">
                    <i className="fa fa-phone fa-rotate-90" /> Book Demo
                </a>
            </div>
        </>
    );
}

export default BackToTop;
