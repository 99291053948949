import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import Drawer from "../Mobile/Drawer";
import BlogHomeOne from "./BlogHomeOne";
import FaqHomeOne from "./FaqHomeOne";
import FeaturesHomeOne from "./FeaturesHomeOne";
import FooterHomeOne from "./FooterHomeOne";
import HeroHomeOne from "./HeroHomeOne";
import HomeOneHeader from "./HomeOneHeader";
import PricingHomeOne from "./PricingHomeOne";
import ProjectHomeOne from "./ProjectHomeOne";
import ServicesHomeOne from "./ServicesHomeOne";
import WhyChooseUs from "./WhyChooseUs";
import TeamHomeOne from "./TeamHomeOne";
import TestimonialHomeOne from "./TestimonialHomeOne";
import TrafficHomeOne from "./TrafficHomeOne";
import ContactHomeOne from "./ContactHomeOne";
import VisionMission from "./VisionMission";

function HomeOne() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} />
      <HeroHomeOne />
      <ServicesHomeOne />
      <WhyChooseUs />
      <VisionMission />
      {/* <FeaturesHomeOne />
      <TrafficHomeOne /> */}
      <TestimonialHomeOne />
      {/* <TeamHomeOne /> */}
      {/* <PricingHomeOne /> */}
      <FaqHomeOne />
      <ContactHomeOne />
      {/* <BlogHomeOne /> */}
      {/* <ProjectHomeOne /> */}
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default HomeOne;
